export const SET_STORED_CARD_LIST = 'SET_STORED_CARD_LIST';
export const SET_STORED_CARDS_FETCHED = 'SET_STORED_CARDS_FETCHED';
export const SET_ACCEPTED_CARD_TYPES = 'SET_ACCEPTED_CARD_TYPES';
export const SET_AVAILABLE_MONTHS = 'SET_AVAILABLE_MONTHS';
export const SET_AVAILABLE_YEARS = 'SET_AVAILABLE_YEARS';

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Action/setStoredCardList */
export const setStoredCardList = (storedCardList) => ({
    type: SET_STORED_CARD_LIST,
    storedCardList
});

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Action/setStoredCardsFetched */
export const setStoredCardsFetched = (storedCardsFetched) => ({
    type: SET_STORED_CARDS_FETCHED,
    storedCardsFetched
});

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Action/setAcceptedCardTypes */
export const setAcceptedCardTypes = (acceptedCardTypes) => ({
    type: SET_ACCEPTED_CARD_TYPES,
    acceptedCardTypes
});

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Action/setAvailableMonths */
export const setAvailableMonths = (availableMonths) => ({
    type: SET_AVAILABLE_MONTHS,
    availableMonths
});

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Action/setAvailableYears */
export const setAvailableYears = (availableYears) => ({
    type: SET_AVAILABLE_YEARS,
    availableYears
});
