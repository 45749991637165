import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CustomAssortmentMenuOption from 'Component/CustomAssortmentMenuOption';
import { CustomAssortmentOptionsType } from 'Type/CustomAssortmentProduct.type';

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOptions/Component */
export class CustomAssortmentMenuOptionsComponent extends PureComponent {
    static propTypes = {
        options: CustomAssortmentOptionsType.isRequired,
        incrementValue: PropTypes.number.isRequired,
        remainingQty: PropTypes.number.isRequired
    };

    render() {
        const { options, incrementValue, remainingQty } = this.props;

        return (
            <div block="CustomAssortmentMenuOptions">
                { options?.map((option) => {
                    const { sku } = option;

                    return (
                        <CustomAssortmentMenuOption
                            key={ sku }
                            option={ option }
                            incrementValue={ incrementValue }
                            remainingQty={ remainingQty }
                        />
                    );
                }) }
            </div>
        );
    }
}

export default CustomAssortmentMenuOptionsComponent;
