import {
    SET_ACCEPTED_CARD_TYPES,
    SET_AVAILABLE_MONTHS,
    SET_AVAILABLE_YEARS,
    SET_STORED_CARD_LIST,
    SET_STORED_CARDS_FETCHED
} from 'Store/TokenBase/TokenBase.action';

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Reducer/getInitialState */
export const getInitialState = () => ({
    acceptedCardTypes: [],
    storedCardList: [],
    storedCardsFetched: false
});

/** @namespace EroswholesaleScandipwa/Store/TokenBase/Reducer/TokenBaseReducer */
export const TokenBaseReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_STORED_CARD_LIST:
            const { storedCardList } = action;

            return {
                ...state,
                storedCardList
            };
        case SET_STORED_CARDS_FETCHED:
            const { storedCardsFetched } = action;

            return {
                ...state,
                storedCardsFetched
            };
        case SET_ACCEPTED_CARD_TYPES:
            const { acceptedCardTypes } = action;

            return {
                ...state,
                acceptedCardTypes
            };
        case SET_AVAILABLE_MONTHS:
            const { availableMonths } = action;

            return {
                ...state,
                availableMonths
            };
        case SET_AVAILABLE_YEARS:
            const { availableYears } = action;

            return {
                ...state,
                availableYears
            };
        default:
            return state;
    }
};

export default TokenBaseReducer;
