import BurgerMenuReducer from 'Store/BurgerMenu/BurgerMenu.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CartItemReducer from 'Store/CartItem/CartItem.reducer';
import CategoryFeaturedProductsReducer from 'Store/CategoryFeaturedProducts/CategoryFeaturedProducts.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import CustomAssortmentMenuReducer from 'Store/CustomAssortmentMenu/CustomAssortmentMenu.reducer';
import FirebaseReducer from 'Store/Firebase/Firebase.reducer';
import HeaderHeightReducer from 'Store/HeaderHeight/HeaderHeight.reducer';
import KlevuReducer from 'Store/Klevu/Klevu.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import PriceRangeReducer from 'Store/PriceRange/PriceRange.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import ShippingEstimatorReducer from 'Store/ShippingEstimator/ShippingEstimator.reducer';
import StoreInPickUpReducer from 'Store/StoreInPickUp/StoreInPickUp.reducer';
import TokenBaseReducer from 'Store/TokenBase/TokenBase.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';

/** @namespace EroswholesaleScandipwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    WishlistReducer,
    NoMatchReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    CheckoutReducer,
    ContactFormReducer,
    ProductCompareReducer,
    StoreInPickUpReducer,
    HeaderHeightReducer,
    BurgerMenuReducer,
    KlevuReducer,
    PriceRangeReducer,
    ShippingEstimatorReducer,
    CustomAssortmentMenuReducer,
    CartItemReducer,
    CategoryFeaturedProductsReducer,
    TokenBaseReducer,
    FirebaseReducer
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
